/* eslint-disable max-len */
import { envVal } from "@root/config/config";

const year = new Date().getFullYear();

export const muzaContent = {
  timezone: "Europe/Moscow",
  yandexMapState: {
    center: [55.7522, 37.6155],
    zoom: 9,
  },
  locale: { _id: 1 },
  title: "2DO2GO Афиша России",
  subTitle: "Федеральная афиша мероприятий",
  mainBanner: {
    hide: true,
    title: "",
    text: "",
    links: [],
  },
  useBvi: false,
  phone: "8 800 200-09-42",
  mail: "support@muzaticket.ru",
  callTime: "В будние дни с 8:00 до 20:00",
  agreementLink: "/static/public-offer.pdf",
  privacyPolicyLink: "/static/privacy-policy.pdf",
  refundPolicyLink: "/static/refund-policy.pdf",
  socials: {
    ok: null,
    vk: null,
    yaDzen: null,
    telegram: null,
    facebook: null,
    instagram: null,
  },
  socialTitles: {
    vk: "вконтакте",
    telegram: "Telegram",
  },
  subscribeForm: {
    title: "Будьте в курсе всех новостей",
  },
  header: {
    links: [
      {
        title: "Афиша",
        relativeLink: "/events",
        links: [
          { title: "Все события", to: "/events" },
          { title: "Пушкинская карта", to: "/events/tag-pushkinskaya-karta" },
          { title: "Концерты и шоу", to: "/events/tag-koncerty-i-shou" },
          { title: "Спектакли", to: "/events/tag-spektakli" },
          { title: "Выставки", to: "/events/tag-vystavki" },
          { title: "Мастер-классы", to: "/events/tag-master-klassy" },
          { title: "Кино", to: "/movies" },
          { title: "Фестивали", to: "/events/tag-festivali" },
          { title: "Праздники", to: "/events/tag-prazdniki" },
        ],
      },
      { title: "Места", to: "/places" },
      { title: "Маршруты", to: "/touristroutes" },
      { title: "Обзоры", to: "/overviews" },
    ],
  },
  footer: {
    title: "",
    logoPath: "/images/logo_footer.svg",
    systemName: `© ${year}. 2DO2GO Афиша России. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: "Афиша", to: "/events" },
      { title: "Абонементы", to: "/ticketsbundles" },
      { title: "Места", to: "/places" },
      { title: "Маршруты", to: "/touristroutes" },
      { title: "Обзоры", to: "/overviews" },
    ],
  },
  analytics: {
    yandex: envVal({ production: "99775833" }, null),
    gtm: null,
    pixel: envVal({ production: "" }, null),
    goals: {
      paidVisit: "events_visit_muza",
      paidBuy: "buy_ticket_muza",
      freeVisit: "events_visits_muza",
      freeNoTicketsVisit: "noactive_free_muza",
      freeBuy: "freeandzero_muza",
      externalLinkVisit: "events_visit_redirect_muza",
      externalLinkBuy: "buy_redirect_muza",
      ticketsAdd: "entity_ticket_add",
    },
  },
  unisenderNewsPopup: {
    id: null,
  },
  componentsConfig: {
    header: "default",
    footer: "default",
    heroBlock: "banner",
    socialCardIconVk: "v2",
    socialCardIconTg: "v2",
    pages: {
      index: {},
      places: {},
      singlePlace: {},
    },
  },
  seo: {
    main: "Билетная платформа «Муза»",
    pages: {
      index: {
        title: () => "Билетная платформа «Муза»",
        description: () => "Билетная платформа «Муза»",
        titleOg: () => "Билетная платформа «Муза»",
      },
      main: { title: "Билетная платформа «Муза»" },
    },
  },
};
